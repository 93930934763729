// ----------------------------
//     HOMEPAGE - home-profile
// ----------------------------

.home-profile {
  padding: 50px 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &-intro {
    position: relative;
  }

  @include breakpoint(xs) {
    padding: 50px 0 40px 0;
  }

  &__title {
    position: relative;
    z-index: 1;
  }
  
  &-box {
    position: relative;
    // top: 0;
    float: left;
    background-position: center;
    background-size: cover;
    padding-bottom: 33.3%; 
    width: 28.4%;
    overflow: hidden;

    @include breakpoint(xs) {
      padding-bottom: 73.3%;
      width: 58%;
    }

    &--01 {
      z-index: 2;
      top: 10px;
      left: 0;

      span {
        bottom: 35px;
        left: 20px;
        @include rotateElement( 10deg );

        @include breakpoint(xs) {
          bottom: 25px;
        }

      }

    }

    &--02 {
      z-index: 3;
      top: 15px;
      left: -2%;
      @include rotateElement( 6deg );

      @include breakpoint(xs) {
        position: absolute;
        top: 60px;
        right: -27px;
        left: auto;
      }

      span {
        right: 70px;
        bottom: 25px;

        @include breakpoint(xs) {
          bottom: 18px;
        }

      }

    }

    &--03 {
      z-index: 4;
      left: -5.5%;
      @include rotateElement( 355.2deg );

      @include breakpoint(xs) {
        top: -10px;
        left: -4.5%;
      }

      span {
        bottom: 25px;
        left: 50px;

        @include breakpoint(xs) {
          bottom: 20px;
          left: 30px;
        }

      }

    }

    &--04 {
      position: absolute;
      z-index: 5;
      right: -3%;
      @include rotateElement( 8deg );

      @include breakpoint(xs) {
        top: auto;
        right: -4%;
        bottom: 40px;
      }

      span {
        right: 50px;
        bottom: 30px;
        @include rotateElement( 357deg );

        @include breakpoint(xs) {
          bottom: 20px;
        }

      }

    }

    &--01::after,
    &--03::after {
      position: absolute;
      bottom: 0;
      left: 0;
      border-color: transparent transparent transparent $second-color;
      border-style: solid;
      border-width: 125px 0 0 690px;
      width: 0;
      height: 0;
      content: "";

      @include breakpoint($max: 1600) {
        border-width: 95px 0 0 600px;
      }

      @include breakpoint(sm-below) {
        border-width: 85px 0 0 600px;
      }

      @include breakpoint(xs) {
        border-width: 65px 0 0 600px;
      }

    }

    &--02::after,
    &--04::after {
      position: absolute;
      bottom: 0;
      right: 0;
      border-color: transparent transparent $main-color transparent;
      border-style: solid;
      border-width: 0 0 125px 690px;
      width: 0;
      height: 0;
      content: "";

      @include breakpoint($max: 1600) {
        border-width: 0 0 95px 600px;
      }

      @include breakpoint(sm-below) {
        border-width: 0 0 85px 600px;
      }

      @include breakpoint(xs) {
        border-width: 0 0 65px 600px;
      }

    }

    span {
      position: absolute;
      z-index: 10;
      text-transform: uppercase;
      color: #fff;
      font-size: 30px;
      font-weight: 800;

      @include breakpoint($max: 1600) {
        font-size: 25px;
      }

      @include breakpoint(md-below) {
        font-size: 22px;
      }

      @include breakpoint(xs) {
        font-size: 15px;
      }

    }

    &--clean::after {
      display: none;
    }

  }

  &-more {
    padding: 15px 0 20px 0;

    &__left,
    &__right {
      margin-bottom: 25px; 
      line-height: 28px;
      font-size: 17px;

      @include breakpoint(sm-below) {
        margin-bottom: 20px; 
        line-height: 24px;
        font-size: 15px;
      }

    }

    &__left {
      padding-right: 40px;

      @include breakpoint(md-below) {
        padding-right: 0;
      }

    }

    &__right {
      padding-top: 112px;
      padding-left: 40px;

      @include breakpoint(md-below) {
        padding-top: 0;
        padding-left: 0;
      }

    }

    &__text {
      padding-top: 40px;
      text-align: center;
      text-transform: uppercase;
      color: $second-color;
      font-size: 48px;
      font-weight: 800;

      @include breakpoint(md-below) {
        padding-top: 30px;
        font-size: 40px;
      }

      @include breakpoint(sm-below) {
        padding-top: 20px;
        font-size: 35px;
      }

      @include breakpoint(xs) {
        padding-top: 10px;
        font-size: 30px;
      }

    }

    &__link {
      color: $main-color;

      &:hover {
        color: darken($main-color, 15%);
      }

      &--underline {
        color: $second-color;
        text-decoration: underline;

        &:hover {
          color: darken($second-color, 15%);
          text-decoration: underline;
        }

      }

    }

  }

}

// ----------------------------
//     HOMEPAGE - home-take
// ----------------------------

.home-take {
  position: relative;
  margin-top: -250px;
  background-color: #070707;
  background-image: url(../img/bg-profile.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 380px 0 130px 0;

  @include breakpoint(md-below) {
    padding: 320px 0 130px 0;
  }

  @include breakpoint(xs) {
    padding: 340px 0 130px 0; 
  }

  .inside {
    position: relative;

    &::after,
    &::before {
      position: absolute;
      bottom: 0;
      border-style: solid;
      border-width: 0 500px 280px 500px;
      border-color: transparent transparent rgba(#ffffff,0.05) transparent;
      width: 0;
      height: 0;
      content: "";
    }

    &::after {
      top: 4%;
      left: 200px;
      @include rotateElement( 10deg );
    }

    &::before {
      top: 19%;
      left: -630px;
      @include rotateElement( 343deg );
    }

  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url(../img/bg-profile--01.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";

    @include breakpoint($max: 1600) {
      left: 40%;
      background-position: bottom right;
    }

    @include breakpoint(md-below) {
      display: none;
    }

  }

  &__text {
    margin-bottom: 25px; 
    line-height: 26px;
    color: #808080;
    font-size: 17px;

    @include breakpoint($max: 1600) {
      line-height: 24px;
      font-size: 16px;
    }

    @include breakpoint(sm-below) {
      margin-bottom: 20px; 
      line-height: 24px;
      font-size: 15px;
    }

  }

  &__link {
    display: inline-block;
    position: relative;
    margin-top: 35px;
    margin-bottom: 70px;
    text-decoration: underline;
    text-transform: uppercase;
    color: #ec328a;
    font-size: 56px;
    font-weight: 800;

    @include breakpoint(md-below) {
      margin-top: 25px;
      margin-bottom: 60px;
      font-size: 40px;
    }

    @include breakpoint(xs) {
      margin-top: 15px;
      margin-bottom: 40px;
      font-size: 35px;
    }

    &::after,
    &::before {
      position: absolute;
      @include rotateElement( 339deg );
      @include transition;
      background-image: url(../img/sign-up.png);
      background-position: center;
      background-repeat: no-repeat;
      content: "";

      @include breakpoint(xs) {
        display: none;
      }

    }

    &::after {
      top: 0;
      left: 0;
      opacity: 0.8;
      background-size: 198px 27px;
      width: 198px;
      height: 27px;
    }

    &::before {
      top: 28px;
      left: 186px;
      opacity: 0.7;
      background-size: 75px 10px;
      width: 75px;
      height: 10px;
    }

    &:hover {
      text-decoration: underline;
      color: lighten(#ec328a,7%);

      &::after,
      &::before {
        opacity: 1;
      }

    }

  }

}

// ----------------------------
//     HOMEPAGE - home-look
// ----------------------------

.home-look {
  position: relative;
  margin-top: -100px;
  background-color: #ec328a;
  background-image: url(../img/bg-look.jpg);
  background-position: top 140px left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 60px;

  @include breakpoint($max: 1850) {
    background-position: top 100px left;
  }

  @include breakpoint($max: 1500) {
    background-position: top 0 left;
  }

  @include breakpoint(xs) {
    background-image: none;
  }

  &::after,
  &::before {
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    content: "";
    z-index: 1;
  }

  &::before {
    top: 0;
    right: -30px;
    border-width: 200px 2000px 0 0;
    border-color: #070707 transparent transparent transparent;
    z-index: 1;

    @include breakpoint(md-below) { 
      display: none;  
    }

  }

  &::after {
    bottom: 0;
    left: -30px;
    border-width: 0 0 200px 2000px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 1;
  }

  > div {
    position: relative;
    z-index: 15;
  }

  &__text {
    margin-bottom: 25px; 
    line-height: 24px;
    color: #fff;
    font-size: 16px;

    @include breakpoint($max: 1600) {
      line-height: 24px;
      font-size: 16px;
    }

    @include breakpoint(sm-below) {
      margin-bottom: 20px; 
      line-height: 24px;
      font-size: 15px;
    }

    &-box {
      float: right;
      padding-top: 12px;
      max-width: 367px;

      @include breakpoint(md-below) {
        padding-left: 15px;
        max-width: 100%;
      }

      @include breakpoint(xs) {
        padding-right: 15px;
      }

    }

  }

  &-profile {
    padding: 10px 0 60px 0;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    &-box {
      position: relative;
      // top: 0;
      float: left;
      background-position: center;
      background-size: cover;
      padding-bottom: 50.1%; 
      width: 38.4%;
      overflow: hidden;

      &--01 {
        position: absolute;
        z-index: 2;
        margin-top: 90px;
        left: 33px;
        @include rotateElement( 5deg );

        @include breakpoint(sm-below) {
          left: 3px;
        }

      }

      &--02 {
        z-index: 4;
        left: 30%;
        margin-top: 55px;
        @include rotateElement( 356deg );

        span {
          right: 30px;
          bottom: 25px;
        }

      }

      &--03 {
        position: absolute;
        z-index: 3;
        right: 0;
        margin-top: 10px;
      }

      span {
        position: absolute;
        z-index: 10;
        text-transform: uppercase;
        color: #fff;
        font-size: 30px;
        font-weight: 800;
      }

    }

  }

}

// ----------------------------
//     HOMEPAGE - home-hot
// ----------------------------

.home-hot {
  position: relative;
  z-index: 99;
  margin-top: -100px;
  padding-bottom: 120px;

  @include breakpoint($max: 1600) {
    margin-top: -60px;
  }

  @include breakpoint(md-below) {
    margin-top: -20px;
  }

  @include breakpoint(xs) {
    padding-bottom: 0px;
  }

  &__title {

    @include breakpoint(md-below) {
      margin-bottom: 100px;
    }

  }

  &__text {
    margin-bottom: 25px; 
    line-height: 26px;
    font-size: 17px;

    @include breakpoint($max: 1600) {
      line-height: 24px;
      font-size: 16px;
    }

    @include breakpoint(sm-below) {
      margin-bottom: 20px; 
      line-height: 24px;
      font-size: 15px;
    }

    a {
      color: $links__text-color;

      &:hover {
        color: darken($links__text-color,10%);
      }

    }

    &--sign {
      text-transform: uppercase;
      padding-top: 25px;
      padding-bottom: 20px;
      color: #30d8d6;
      font-size: 30px;
      font-weight: 800;

      @include breakpoint(md-below) {
        padding-top: 20px;
        padding-bottom: 15px;
        font-size: 25px;
      }

      @include breakpoint(xs) {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 23px;
      }

      span {
        display: block;
        line-height: 72px;
        color: #ec328a;
        font-size: 56px;

        @include breakpoint($min: 1200) {
          white-space: nowrap;
        }

        @include breakpoint(md-below) {
          line-height: 42px;
          font-size: 40px;
        }

        @include breakpoint(xs) {
          display: inline-block;
          font-size: 23px;
        }

      }

    }

  }

  &__link {
    display: inline-block;
    text-decoration: underline;
    color: #ec328a;
    font-weight: 800;

    &:hover {
      text-decoration: underline;
      color: lighten(#ec328a,7%);
    }

  }

  &-profile {
    position: relative;
    margin-top: -70px;
    padding: 10px 0 180px 0;
    overflow-y: auto;
    overflow-x: hidden;

    @include breakpoint(sm-below) {
      padding: 10px 0 120px 0;
    }

    @include breakpoint(xs) {
      padding: 10px 0 100px 0;
    }

    @include breakpoint($max: 600) {
      padding: 10px 0 60px 0;
    }

    &::before,
    &::after {
      position: absolute;
      border-style: solid;
      border-width: 100px 200px 100px 0;
      border-color: transparent $main-color transparent transparent;
      width: 0;
      height: 0;
      content: "";

      @include breakpoint($max: 600) {
        display: none;
      }

    }

    &::before {
      z-index: -1;
      bottom: 159px;
      left: 130px;
      @include rotateElement( 86.3deg );

      @include breakpoint(md-below) {
        left: 70px;
      }

      @include breakpoint(sm-below) {
        bottom: 99px;
      }

    }

    &::after {
      z-index: -1;
      top: 19px;
      left: 315px;
      @include rotateElement( 116.3deg );

      @include breakpoint(md-below) {
        left: auto;
        right: 70px;
      }

    }

    &-box {
      position: relative;
      top: 0;
      float: left;
      background-position: center;
      background-size: cover;
      padding-bottom: 73.9%; 
      width: 56.9%;
      overflow: hidden;

      &--01 {
        z-index: 2;
        left: 33px;
        margin-top: 10px;
        @include rotateElement( 356deg );

        @include breakpoint($max: 600) {
          left: 19px;
        }

      }

      &--02 {
        position: absolute;
        @include rotateElement( 6.3deg );
        right: 50px;
        z-index: 3;
        margin-top: 80px;

        @include breakpoint($max: 600) {
          right: 22px;
          margin-top: 50px;
        }

      }

      span {
        position: absolute;
        z-index: 10;
        text-transform: uppercase;
        color: #fff;
        font-size: 30px;
        font-weight: 800;
      } 

    }

  }

}