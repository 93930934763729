// -------------------------------
//     BUTTONS STYLES
// -------------------------------

.btn {
    @include transition;
    @include borderRadius(0px);
    text-align: center;

    &:hover,
    &:focus,
    &:active {
        @include boxShadowReset;
    }

}

.btn.btn-primary {
    border: 0;
    background: $second-color;
    padding: 12px 25px 10px 25px;
    color: $button__text-color;
    text-transform: uppercase;
    font-size: $button__font-size;
    font-weight: 800;

    &:active,
    &:focus,
    &:hover {
        background: darken($second-color,7%);
        color: $button__text-color;
        opacity: 0.8;
    }

    &--rounded {
        @include borderRadius(15px);
    }

}

.btn.btn-empty {
    border: 0;
    background: transparent;
    padding: 0;
    color: #fff;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 800;

    &:active, 
    &:focus,
    &:hover {
        border: 0;
        opacity: 0.6;
        background: transparent;
        text-decoration: underline;
        color: #fff; 
    }

    &--small {
        display: inline-block;
        padding: 4px 0;
        font-size: 16px;

        @include breakpoint(sm-below) { 
            float: none !important;
            color: $second-color;
        }

        &:active,
        &:focus,
        &:hover {

            @include breakpoint(sm-below) {
                color: $main-color;
            }

        }

    } 

}

.button--full-width {
    display: block;
    width: 100%;
}  