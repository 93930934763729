// -------------------------------
//     TYPOGRAPHY STYLES
// -------------------------------

// *--------- Import ---------*

@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');

// *--------- GENERAL ---------*

body {
    font: $base__font-weight #{$base__font-size}/1 $base__font-family;
    color: $base__text-color;
}

strong {
    font-weight: $base__font-weight--bold;
}

a {
    @extend %transition;

    &:hover,
    &:focus{
        text-decoration: none;
    }

}

p {
    font-size: 14px;
    line-height: 20px;
}