// -------------------------------
//     MIXINS
// -------------------------------

// *--------- BOX SIZING ---------*
@mixin box-sizing($type) {
    box-sizing: $type;
}

// *---------  BORDER RADIUS  ---------*
@mixin borderRadius($type: 5px) {
    border-radius: $type;
}

@mixin borderRadiusOdd($typeTop: 5px, $typeRight: 5px, $typeBottom: 5px, $typeLeft: 5px) {
    border-radius: $typeTop $typeRight $typeBottom $typeLeft;
}

// *--------- CLEARFIX ---------*
@mixin clearfix {
    &:before,
    &:after {
        display: table;
        content: "";
    }

    &:after {
        clear :both;
    }

    & {
        *zoom: 1;
    }
}

// *--------- TRANSITIONS ---------*
@mixin transition($props: all, $speed: 0.2s, $easing: ease-in-out) {
    transition: $props $speed $easing;
}

// *--------- TEXT-SHADOW ---------*
@mixin textWhiteShadow($levelOfShadow: 0.1) {
    text-shadow: 1px 1px 0 rgba(255,255,255,$levelOfShadow);
}

@mixin textDarkShadow($levelOfShadow: 0.1) {
    text-shadow: 1px 1px 0 rgba(0,0,0,$levelOfShadow);
}

// *--------- ROTATE ---------*
@mixin rotateElement($deg: 180deg) {
    transform: rotate($deg);
}

// *--------- BOX SHADOW ---------*

@mixin boxShadow($horizontLength: 1px, $verticalLength: 1px, $blurRadius: 0px, $spread: 0, $color: #fff) {
    box-shadow: $horizontLength $verticalLength $blurRadius $spread $color;
}

@mixin boxShadowInset($horizontLength: 1px, $verticalLength: 1px, $blurRadius: 0px, $spread: 0, $color: #fff) {
    box-shadow: inset $horizontLength $verticalLength $blurRadius $spread $color;
}

@mixin boxShadowWithInset($horizontLengthInset: 1px, $verticalLengthInset: 1px, $blurRadiusInset: 0px, $spreadInset: 0, $colorInset: #fff, $horizontLength: 1px, $verticalLength: 1px, $blurRadius: 0px, $spread: 0, $color: #fff) {
    box-shadow: inset $horizontLengthInset $verticalLengthInset $blurRadiusInset $spreadInset $colorInset, $horizontLength $verticalLength $blurRadius $spread $color;
}

@mixin boxShadowReset() {
    box-shadow: none;
}

// *--------- CUSTOM SELECT ---------*

@mixin cursor-disabled() {
    cursor: not-allowed;
}

@mixin box-sizing($fmt) {
    box-sizing: $fmt;
}

@mixin box-shadow($fmt) {
    box-shadow: $fmt;
}

@function fade($color, $amnt) {
    @if $amnt > 1 {
        $amnt: $amnt / 100; // convert to percentage if int
    }
    @return rgba($color, $amnt);
}

// *--------- GRADIENT ---------*

@mixin gradientHorizontal($gradientStart: #fff, $gradientEnd: #000) {
    background: $gradientStart;
    background: -moz-linear-gradient(left, $gradientStart 0%, $gradientEnd 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, $gradientStart), color-stop(100%, $gradientEnd));
    background: -webkit-linear-gradient(left, $gradientStart 0%, $gradientEnd 100%);
    background: -o-linear-gradient(left, $gradientStart 0%, $gradientEnd 100%);
    background: -ms-linear-gradient(left, $gradientStart 0%, $gradientEnd 100%);
    background: linear-gradient(to right, $gradientStart 0%, $gradientEnd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientStart', endColorstr='$gradientEnd', GradientType=1 );
}

@mixin gradientVertical($gradientStart: #fff, $gradientEnd: #000) {
    background: $gradientStart;
    background: -moz-linear-gradient(top, $gradientStart 0%, $gradientEnd 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $gradientStart), color-stop(100%, $gradientEnd));
    background: -webkit-linear-gradient(top, $gradientStart 0%, $gradientEnd 100%);
    background: -o-linear-gradient(top, $gradientStart 0%, $gradientEnd 100%);
    background: -ms-linear-gradient(top, $gradientStart 0%, $gradientEnd 100%);
    background: linear-gradient(to bottom, $gradientStart 0%, $gradientEnd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientStart', endColorstr='$gradientEnd', GradientType=0 );
}

/* Breakpoints manager (RWD)
///
/// @author I&B
///
/// @access public
///
/// @param {Number} $min - Screen min-width (without unit)
/// @param {Number} $max - Screen max-width (without unit)
/// @param {String} $class - Predefined Bootstrap breakpoints
///
/// @requires $min and $max; or $min; or $max; or $class
///
/// @example scss - Usage
/// .header {
///   @include breakpoint(sm) {
///     width: 50%;
///   }
/// }
/// .footer {
///   @include breakpoint($min:600 $max:1200) {
///     height: auto;
///   }
/// }
///
/// @example css - CSS output
/// @media (min-width: 768px) {
///   .header {
///     width: 50%;
///   }
/// }
/// @media (min-width: 600px) and (max-width: 1200px) {
///   .footer {
///     height: auto;
///   }
/// } */

@mixin breakpoint($min: 0, $max: 0) {
    $type: type-of($min);

    @if $type == string {
        $class: $min;

        @if $class == xs {
            @media (max-width: 767px) { @content; }
        }

        @else if $class == sm {
            @media (min-width: 768px) { @content; }
        }

        @else if $class == md {
            @media (min-width: 992px) { @content; }
        }

        @else if $class == lg {
            @media (min-width: 1200px) { @content; }
        }

        @else if $class == sm-below {
            @media (max-width: 991px) { @content; }
        }

        @else if $class == md-below {
            @media (max-width: 1199px) { @content; }
        }

        @else if $class == sm-only {
            @media (min-width: 768px) and (max-width: 991px) { @content; }
        }

        @else if $class == md-only {
            @media (min-width: 992px) and (max-width: 1199px) { @content; }
        }

        @else {
            @warn "Breakpoint {{[[-mixin-]]}} supports: xs, sm, md, lg, sm-below, sm-only, md-only";
        }
    }
    @else if $type == number {
        $query: "all" !default;
        @if $min != 0 and $max != 0 {
            $query: "(min-width: #{$min}px) and (max-width: #{$max}px)";
        }
        @else if $min != 0 and $max == 0 {
            $query: "(min-width: #{$min}px)";
        }
        @else if $min == 0 and $max != 0 {
            $query: "(max-width: #{$max}px)";
        }
        @media #{$query} {
            @content;
        }
    }
}