/*------------------------------------*\
    #COMPONENTS / MODAL
\*------------------------------------*/


.modal-header,
.modal-body,
.modal-footer {
  padding: 30px;
}

.modal-heading {
  color: $second-color;
  font-size: 34px;
  font-weight: 700;

  @include breakpoint(md-below) {
    font-size: 28px;
  }
  @include breakpoint(xs) {
    font-size: 24px;
  }
}

.modal {

  .modal-header {
    display: block;
  }

  .close {
    margin: 0;
    padding: 0;
  }

  .btn {
    border: 0;
    
    padding-right: 40px;
    padding-left: 40px;

    min-width: 140px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;

    @include breakpoint(xs) {
      padding-right: 35px;
      padding-left: 35px;
      min-width: 110px;
    }

    &.btn-success {
      background-color: $main-color;

      &:hover,
      &:focus {
        background-color: darken($main-color, 5%);
      }
      &:active {
        background-color: darken($main-color, 10%);
      }
    }

    &.btn-danger {
      background-color: $second-color;

      &:hover,
      &:focus {
        background-color: darken($second-color, 5%);
      }
      &:active {
        background-color: darken($second-color, 10%);
      }
    }
  }

  img {
    display: block;

    margin-right: auto;
    margin-left: auto;
    
    max-width: 50%;
  }

  .text-block {
    line-height: 24px;
    font-size: 16px;

    p,
    img {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      line-height: 24px;
      font-size: 16px;
    }
  }

  .main-form {
    margin-top: 30px;

    .form-control {
      border-radius: 0;
      font-size: 18px;

      &:focus {
        border-color: $main-color;
        box-shadow: 0 0 0 .2rem rgba($main-color, .25)
      }
    }

    .btn {
      width: 100%;
    }
  }
}

.modal-dialog {
  @include breakpoint(sm) {
    max-width: 600px;
  }
}

.modal-footer {
  justify-content: center;
}