// ----------------------------
//     HEADER STYLES
// ----------------------------

.header {
    position: relative;
    border-bottom: 0;
    background: none;
    width: 100%;
    min-width: 320px;
    @include transition;

    .inside {
        max-width: 1310px;
        position: relative;

        @include breakpoint($min: 992, $max: 1800) {
            padding: 0;
        }

        @include breakpoint(md-below) {
            padding: 0;
        }

    }

    .logo {
        position: relative;
        z-index: 99;
        margin: 0 auto;
        width: 350px;
    }

    &__logo {
        display: block;
        padding: 35px 0 30px 0;
        text-align: center;
        color: #fff;
        font-family: $secondary__font-family;
        font-size: 56px;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
        }

        &-word {
            display: inline-block;

            &--first {
                text-shadow: #ec328a 6px 0px 0px, #ec328a 5.91667px 1px 0px, #ec328a 5.66667px 1.96667px 0px, #ec328a 5.26667px 2.88333px 0px, #ec328a 4.71667px 3.71667px 0px, #ec328a 4.03333px 4.43333px 0px, #ec328a 3.25px 5.05px 0px, #ec328a 2.36667px 5.51667px 0px, #ec328a 1.41667px 5.83333px 0px, #ec328a 0.416667px 5.98333px 0px, #ec328a -0.566667px 5.96667px 0px, #ec328a -1.55px 5.8px 0px, #ec328a -2.5px 5.45px 0px, #ec328a -3.36667px 4.96667px 0px, #ec328a -4.15px 4.33333px 0px, #ec328a -4.8px 3.58333px 0px, #ec328a -5.33333px 2.75px 0px, #ec328a -5.71667px 1.81667px 0px, #ec328a -5.93333px 0.85px 0px, #ec328a -6px -0.15px 0px, #ec328a -5.88333px -1.15px 0px, #ec328a -5.61667px -2.1px 0px, #ec328a -5.2px -3px 0px, #ec328a -4.61667px -3.83333px 0px, #ec328a -3.91667px -4.53333px 0px, #ec328a -3.11667px -5.13333px 0px, #ec328a -2.21667px -5.56667px 0px, #ec328a -1.26667px -5.86667px 0px, #ec328a -0.266667px -6px 0px, #ec328a 0.716667px -5.95px 0px, #ec328a 1.7px -5.75px 0px, #ec328a 2.63333px -5.38333px 0px, #ec328a 3.48333px -4.88333px 0px, #ec328a 4.25px -4.23333px 0px, #ec328a 4.9px -3.46667px 0px, #ec328a 5.4px -2.61667px 0px, #ec328a 5.76667px -1.68333px 0px, #ec328a 5.96667px -0.7px 0px;
            }

            &--second {
                position: relative;
                top: 22px;
                @include rotateElement( 350deg ); 
                margin-left: -50px;
                text-shadow: #30d8d6 6px 0px 0px, #30d8d6 5.91667px 1px 0px, #30d8d6 5.66667px 1.96667px 0px, #30d8d6 5.26667px 2.88333px 0px, #30d8d6 4.71667px 3.71667px 0px, #30d8d6 4.03333px 4.43333px 0px, #30d8d6 3.25px 5.05px 0px, #30d8d6 2.36667px 5.51667px 0px, #30d8d6 1.41667px 5.83333px 0px, #30d8d6 0.416667px 5.98333px 0px, #30d8d6 -0.566667px 5.96667px 0px, #30d8d6 -1.55px 5.8px 0px, #30d8d6 -2.5px 5.45px 0px, #30d8d6 -3.36667px 4.96667px 0px, #30d8d6 -4.15px 4.33333px 0px, #30d8d6 -4.8px 3.58333px 0px, #30d8d6 -5.33333px 2.75px 0px, #30d8d6 -5.71667px 1.81667px 0px, #30d8d6 -5.93333px 0.85px 0px, #30d8d6 -6px -0.15px 0px, #30d8d6 -5.88333px -1.15px 0px, #30d8d6 -5.61667px -2.1px 0px, #30d8d6 -5.2px -3px 0px, #30d8d6 -4.61667px -3.83333px 0px, #30d8d6 -3.91667px -4.53333px 0px, #30d8d6 -3.11667px -5.13333px 0px, #30d8d6 -2.21667px -5.56667px 0px, #30d8d6 -1.26667px -5.86667px 0px, #30d8d6 -0.266667px -6px 0px, #30d8d6 0.716667px -5.95px 0px, #30d8d6 1.7px -5.75px 0px, #30d8d6 2.63333px -5.38333px 0px, #30d8d6 3.48333px -4.88333px 0px, #30d8d6 4.25px -4.23333px 0px, #30d8d6 4.9px -3.46667px 0px, #30d8d6 5.4px -2.61667px 0px, #30d8d6 5.76667px -1.68333px 0px, #30d8d6 5.96667px -0.7px 0px;
            }

        }

    }

    .navbar-toggle {
        position: absolute;
        top: -68px;
        right: 10px;
        width: 44px;
        height: 44px;
        z-index: 999;

        @include breakpoint(sm-below) {
            top: 2px;
            right: 10px;
        }

    }

    &--home {
        position: relative;
        margin-bottom: -150px;
        background-color: #ec328a;
        background-image: url(../img/bg-home.jpg);
        background-position: center left;
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 80px;
        height: auto;

        @include breakpoint(sm-below) {
            background-position: top left;
        }

        @include breakpoint(xs) {
            margin-bottom: -110px;
            background-image: none;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-top: -50%;
            background: rgba(48,216,213,1);
            background: -moz-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(48,216,213,1)), color-stop(21%, rgba(48,216,213,1)), color-stop(80%, rgba(48,216,213,0)));
            background: -webkit-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -o-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -ms-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: radial-gradient(ellipse at center, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30d8d5', endColorstr='#30d8d5', GradientType=1 );
            content: "";

            @include breakpoint(xs) {
                opacity: 0.5;
            }

        }

    }

    &--login {
        position: relative;
        margin-bottom: -150px;
        background-color: #ec328a;
        padding-bottom: 80px;
        height: auto;

        @include breakpoint(xs) {
            margin-bottom: -110px;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-top: -50%;
            background: rgba(48,216,213,1);
            background: -moz-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(48,216,213,1)), color-stop(21%, rgba(48,216,213,1)), color-stop(80%, rgba(48,216,213,0)));
            background: -webkit-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -o-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: -ms-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            background: radial-gradient(ellipse at center, rgba(48,216,213,1) 0%, rgba(48,216,213,1) 21%, rgba(48,216,213,0) 80%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30d8d5', endColorstr='#30d8d5', GradientType=1 );
            content: "";

            @include breakpoint(xs) {
                opacity: 0.5;
            }

        }

    }

    &-top {
        padding-bottom: 30px;

        .inside {
            position: relative;
        }

    }

    .header-welcome {
        padding-top: 50px;

        .inside {

            @include breakpoint($max: 1600) {
                padding: 0 15px; 
            }

        }

        @include breakpoint(md-below) {
            padding-top: 30px;
        }

        @include breakpoint(sm-below) {
            padding-top: 0;
        }

        &__box {

            &--01 {
                position: relative;
                padding-top: 90px;

                @include breakpoint($max: 1600) {
                    padding-top: 0;
                }

                .header-welcome__box-search {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #fff;
                    font-size: 56px;
                    font-weight: 800;
                    text-transform: uppercase;

                    @include breakpoint($min: 1600) {
                        white-space: nowrap;
                    }

                    @include breakpoint($max: 1600) {
                        position: relative;
                        margin-bottom: 10px;
                    }

                    @include breakpoint(md-below) {
                        font-size: 46px;
                    }

                    @include breakpoint(sm-below) {
                        font-size: 36px;
                        text-align: center;
                    }

                }

            }

            &--02 {
                position: relative;

                &::before {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    z-index: 2;
                    width: 261px;
                    height: 392px;
                    background-color: #30d8d6;
                    content: "";
                }

            }

            &-img {
                position: absolute;

                &--01 {
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }

                &--02 {
                    top: 0;
                    right: 0;
                    z-index: 3;
                }

            }

            &--03 {
                padding: 80px 0 60px 0;

                @include breakpoint($max: 1600) {
                    padding: 40px 0 30px 0;
                }

                @include breakpoint(md-below) {
                    padding: 0;
                }

            }

        }

        &__text {
            margin-bottom: 23px;
            letter-spacing: -0.1px;
            line-height: 24px;
            color: #fff;
            font-size: 16px;

            @include breakpoint(sm-below) {
                margin-bottom: 20px;
                font-size: 15px;
            }

        }

        &__link {
            display: inline-block;
            opacity: 1;
            margin: 10px 0 10px 0;
            text-decoration: underline;
            text-transform: uppercase;
            color: #fff;
            font-size: 48px;
            font-weight: 800;

            @include breakpoint(md-below) {
                font-size: 40px;
            }

            @include breakpoint(sm-below) {
                font-size: 36px;
            }

            @include breakpoint(xs) {
                font-size: 25px;
            }

            &:hover,
            &:focus,
            &:active {
                color: #fff;
                opacity: 0.8;
                text-decoration: underline;
            }

        }

        &__scroll {
            display: block;
            position: relative;
            z-index: 666;
            opacity: 1;
            margin: 20px auto;
            background-image: url(../img/icon__arrow--down.png);
            background-position: 10px 10px;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            width: 50px;
            height: 50px;

            &:hover,
            &:focus,
            &:active {
                background-position: 10px 15px;
                opacity: 0.6; 
            }

        }

    }

}

nav.navbar-collapse {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
    width: auto;

    @include breakpoint(sm-below) {
        display: none;
        top: 0;
        right: 0;
        left: 0;
    }

    &.show {

        @include breakpoint(sm-below) {
            display: block;
        }

    }

}

.navbar-nav {
    display: inline-block;
    width: 100%;

    @include breakpoint(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
    }

    > li {
        display: inline-block;
        padding: 0;

        > .nav__link {
            display: block;
            position: relative;
            z-index: 11;
            margin-left: 42px;
            background: transparent;
            padding: 20px 0 20px 0; 
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;

            @include breakpoint(sm-below) {
                margin-left: 0;
            }

            &::after {
                display: block;
                position: absolute;
                right: 0;
                bottom: 10px;
                left: 0;
                @include transition;
                opacity: 0;
                background-color: #fff;
                height: 2px;
                content: "";

                @include breakpoint(sm-below) {
                    display: none;
                }

            }

            &:hover,
            &:focus,
            &:active,
            &.active {
                background: transparent;
                color: #ffffff;
                font-weight: 700;

                &::after {
                    bottom: 20px;
                    opacity: 0.7;
                }

                @include breakpoint(sm-below) {
                    color: $mobile-menu__link-color-hover;
                }

            }

            &--first {
                margin-right: 0;
            }

        }

    }

}


// ----------------------------
//     MENU TOGGLE
// ----------------------------

.navbar-toggle {
    display: block;
    position: relative;
    margin: 0;
    @include borderRadius(0px);
    border: 8px solid $mobile-menu__button-bg;
    background: $mobile-menu__button-bg;
    padding: 0;
    @include transition;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: none;
    }

    .icon-bar {
        display: block;
        position: absolute;
        left: 0;
        border-radius: 9px;
        background: $mobile-menu__button;
        @include transition;
        width: 100%;
        height: 4px;
        opacity: 1;
    }

    &.collapsed .icon-bar {
        @include rotateElement(0deg);
    }

    & .icon-bar:nth-child(1) {
        top: 16px;
        @include rotateElement(135deg);
    }

    & .icon-bar:nth-child(2) {
        top: 15px;
        opacity: 0;
        left: -30px;
    }

    & .icon-bar:nth-child(3) {
        top: 15px;
        @include rotateElement(-135deg);
    }

    &.collapsed .icon-bar {
        background: $mobile-menu__button-icon;
    }

    &.collapsed .icon-bar:nth-child(1) {
        top: 7px;
        @include rotateElement(0deg);
    }

    &.collapsed .icon-bar:nth-child(2) {
        left: 0;
        opacity: 1;
    }

    &.collapsed .icon-bar:nth-child(3) {
        top: 23px;
        @include rotateElement(0deg);
    }

}

.navbar-collapse.collapse {

    @include breakpoint($min: 992, $max: 1800) {
        padding: 0;
    }

}