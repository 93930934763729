// -------------------------------
//     FORMS STYLES
// -------------------------------

.custom-form {

    .form-control {
        border: 0;
        @include borderRadius(0px);
        @include boxShadowReset;
        @include transition;
        margin-bottom: 14px;
        background: $form-field__background-color;
        padding: 9px 14px 8px 14px;
        height: auto;
        line-height: 30px;
        color: $form-field__text-color;
        font-size: $form-field__font-size;

        &--small {
            padding: 4px 10px 3px 10px;
            font-size: 14px;
        }

        &:hover {
            border: 0;
            background: lighten($form-field__background-color,5%);
            @include boxShadowReset;
        }

        &:focus {
            border: 0;
            background: darken($form-field__background-color,15%);
            @include boxShadowReset;
        }

        &--sel {
            background: $form-field__background-color-2nd;

            &:hover {
                border: 0;
                background: lighten($form-field__background-color-2nd,5%);
                @include boxShadowReset;
            }

            &:focus {
                border: 0;
                background: darken($form-field__background-color-2nd,20%);
                @include boxShadowReset;
            }

        }

        &::-webkit-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::-moz-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &:-ms-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::-ms-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

    }

    textarea {
        resize: none;
    }

    &--search {

        @include breakpoint(xs) {
            text-align: center;
        }

        .btn {
            float: right;
 
            @include breakpoint(xs) {
                float: none;
                margin: 0 auto 20px auto;
            }

        }

        &-small {
            position: absolute;
            top: 17px;
            left: 0px;
            font-size: 0;

            .btn {

                @include breakpoint(sm-below) {
                    float: none;
                    margin-bottom: 15px;
                }

            }

            @include breakpoint(sm-below) {
                position: static;
                border-top: 1px solid lighten($mobile-menu__link-color,35%);
                background-color: lighten($mobile-menu__link-color,43%);
                padding: 15px 15px 0 15px;
                text-align: center;
            }

            div {
                display: inline-block;
                width: 200px;
                margin: 0 20px;

                @include breakpoint(sm-below) {
                    margin: 0;
                    width: 100%;
                }

            }

            .header-welcome__box-search {
                display: inline-block;
                color: #fff;
                font-size: 16px;
                font-weight: 700;

                @include breakpoint(md-below) {
                    display: none;
                }

            }

        }
        
    }

}

// errors

.help-block {
    font-size: 12px;
}

.has-error.has-danger .form-control {
    @include boxShadowReset;
    border: 1px solid #ce352c;
}

// custom checkbox 

.custom-checkbox {

    label {
        display: inline-block;
        position: relative;
        padding-left: 29px; 
        color: #1e1f24;

        &::before {
            display: inline-block;
            position: absolute;
            top: -1px;
            left: 0;
            @include transition;
            border: 1px solid #c7c7c8;
            @include borderRadius(0px);
            background: $form-field__background-color;
            width: 17px;
            height: 17px;
            content: "";
        }

        &::after {
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 3px;
            @include transition;
            opacity: 0;
            background-image: url(../img/icon__checkmark.svg);
            background-repeat: no-repeat;
            background-size: 11px 9px;
            padding-left: 3px;
            padding-top: 1px;
            width: 11px;
            height: 9px;
            content: "";
        }

        &:hover {

            &::before {
                border: 1px solid lighten(#c7c7c8,5%);
            }

        }

    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0; 
        outline: 0;

        &:focus + label::before {
            outline: 0; 
        }

        &:checked + label::after {
            opacity: 1;
        }

        &:disabled + label {
            opacity: 0.65; 
        }

        &:disabled + label::before {
            background-color: #eeeeee;
            cursor: not-allowed; 
        }

    }

}