@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   outline: none;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
   display: block;
}

html {
   @include box-sizing(border-box);
}

*, *:before, *:after {
   @include box-sizing(inherit);
}

body {
   line-height: 1;
}

ol, ul {
   list-style: none;
}

blockquote, q {
   quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
   content: "";
   content: none;
}

table {
   border-collapse: collapse;
   border-spacing: 0;
}

a {
   text-decoration: none;
}

input,
textarea,
button {
   -webkit-appearance: none;
   -webkit-border-radius: 0;
   border-radius: 0;
   outline: none;
}

input[type="radio"] {
   -webkit-appearance: radio;
}

input[type="checkbox"] {
   -webkit-appearance: checkbox;
}

.outline {
   position: absolute;
   clip: rect(0px 0px 0px 0px);
   *clip: rect (0 0 0 0);
}

mark {
   background: none;

   color: inherit;
}