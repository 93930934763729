// ----------------------------
//     CONTENT STYLES
// ----------------------------

.page-title {

    text-align: center;

    span {
        display: inline-block;
        padding-bottom: 20px;

        &:before,
        &:after {
            content: "\00a0";
        }
    }
}

.content {
    padding: 60px 0;
}

a {
    @include transition;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $base__text-color;
}

// ----------------------------
//     ROW SMALL
// ----------------------------

.row.row-small { 
    margin-right: -10px;
    margin-left: -10px;

    [class^='col-lg-'],
    [class^='col-md-'],
    [class^='col-sm-'], {
        padding-right: 10px;
        padding-left: 10px;
    }

    [class^='col-custom-md-'] {
        float: left;
        padding-right: 10px;
        padding-left: 10px;
        width: 20%;

        @include breakpoint(xs) {
            width: 50%;
        }

    }
}