// ----------------------------
//     FOOTER STYLES
// ----------------------------

.site-footer {
    background-color: $footer__background-color;
    padding: 29px 0;
    color: $footer-text__text-color;
    font-size: $footer-text__font-size;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(md-below) {
        padding: 24px 0;
    }

    @include breakpoint(sm-below) {
        padding: 19px 0;
    }

    @include breakpoint(xs) {
        padding: 14px 0;
    }

} 