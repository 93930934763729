// ----------------------------
//     LOGIN - login
// ----------------------------

.login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 96;
  pointer-events: none;

  &-intro {
    position: relative;

    &__title {

      @include breakpoint(md-below) {
        text-align: center;
      }

      &--01,
      &--02 {
        display: block;
        text-transform: uppercase;
        color: #fff;
        font-size: 36px;
        font-weight: 800;

        @include breakpoint(md-below) {
          display: inline-block;
          font-size: 20px;
        }

      }

      &--01 {
        text-align: left;

        @include breakpoint(md-below) {
          text-align: center;
        }

      }

      &--02 {
        text-align: right;

        @include breakpoint(md-below) {
          text-align: center;
        }

      }

    }

  }

  &-box {
    text-align: center;
    font-size: 15px;

    &__text {
      margin: 20px auto 30px auto;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
      color: #fff;
      font-size: 30px;
      font-weight: 800;

      @include breakpoint(md-below) {
        font-size: 25px;
      }

      @include breakpoint(xs) {
        font-size: 20px;
      }
 
    }

  }

  #signup-countdown {
    margin: 0 auto;
    background: url(../img/time-cloud.png) no-repeat;
    padding-top: 10px;
    width: 700px;
    height: 211px;
    text-align: center;
    color: $second-color;
    font-family: $secondary__font-family;
    font-size: 145px;
    font-weight: bold;

    @include breakpoint(md-below) {
      background-size: 500px 151px;
      width: 500px;
      height: 151px;
      font-size: 100px;
    }

    @include breakpoint(xs) {
      background-size: 290px 87px;
      width: 290px;
      height: 87px;
      font-size: 50px;
    }

    span {
      color: $main-color;
    }

  }

  .btn {
    padding: 12px 55px 14px 55px;
    font-size: 30px;
    pointer-events: auto;

    @include breakpoint(xs) {
      padding: 10px 25px 12px 25px;
      font-size: 20px;
    }

  }

}