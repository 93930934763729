// ----------------------------
//     PROFILES - profile-single
// ----------------------------

.profile-single {
  position: relative; 

  &__links {

    @include breakpoint(xs) { 
      padding-top: 10px;
    }

    &--up {
      top: 250px;
      left: -125px;
      z-index: 99;
      background-image: url(../img/rate-up.png);
    }

    &--down {
      top: 310px;
      right: -65px;
      background-image: url(../img/rate-down.png);

      @include breakpoint(sm-below) {
        right: 0;
      }

      @include breakpoint(xs) {
        float: right;
      }

    }

    &--up,
    &--down {
      display: block;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      width: 119px;
      height: 80px;

      &:hover {
        opacity: 0.8;
      }

      @include breakpoint(xs) {
        position: static;
        display: inline-block;
      }

    }

  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin-right: -70%;
    opacity: 0.8;
    background: rgba(48,216,213,1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(48,216,213,1)), color-stop(72%, rgba(48,216,213,0)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
    background: -o-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
    background: radial-gradient(ellipse at center, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30d8d5', endColorstr='#30d8d5', GradientType=1 );
    content: "";

    @include breakpoint(xs) {
      opacity: 0.5;
    }

  }

  &__element {
    position: relative;

    &--01,
    &--03 {

      .profile-single__img {
        z-index: 2;
        @include rotateElement( 7deg );

        > div {

          @include breakpoint(xs) {
            float: none;
          }
          
        }

        &::after {
          position: absolute;
          top: 30px;
          right: 65px;
          z-index: -1;
          @include rotateElement( 7deg );
          border-style: solid;
          border-width: 0 200px 200px 0;
          border-color: transparent #ec3289 transparent transparent;
          width: 0;
          height: 0;
          content: "";

          @include breakpoint(sm-below) {
            display: none !important;
          }

        }

      }

      .profile-single__name span {
        text-shadow: rgb(236, 50, 138) 6px 0px 0px, rgb(236, 50, 138) 5.91667px 1px 0px, rgb(236, 50, 138) 5.66667px 1.96667px 0px, rgb(236, 50, 138) 5.26667px 2.88333px 0px, rgb(236, 50, 138) 4.71667px 3.71667px 0px, rgb(236, 50, 138) 4.03333px 4.43333px 0px, rgb(236, 50, 138) 3.25px 5.05px 0px, rgb(236, 50, 138) 2.36667px 5.51667px 0px, rgb(236, 50, 138) 1.41667px 5.83333px 0px, rgb(236, 50, 138) 0.416667px 5.98333px 0px, rgb(236, 50, 138) -0.566667px 5.96667px 0px, rgb(236, 50, 138) -1.55px 5.8px 0px, rgb(236, 50, 138) -2.5px 5.45px 0px, rgb(236, 50, 138) -3.36667px 4.96667px 0px, rgb(236, 50, 138) -4.15px 4.33333px 0px, rgb(236, 50, 138) -4.8px 3.58333px 0px, rgb(236, 50, 138) -5.33333px 2.75px 0px, rgb(236, 50, 138) -5.71667px 1.81667px 0px, rgb(236, 50, 138) -5.93333px 0.85px 0px, rgb(236, 50, 138) -6px -0.15px 0px, rgb(236, 50, 138) -5.88333px -1.15px 0px, rgb(236, 50, 138) -5.61667px -2.1px 0px, rgb(236, 50, 138) -5.2px -3px 0px, rgb(236, 50, 138) -4.61667px -3.83333px 0px, rgb(236, 50, 138) -3.91667px -4.53333px 0px, rgb(236, 50, 138) -3.11667px -5.13333px 0px, rgb(236, 50, 138) -2.21667px -5.56667px 0px, rgb(236, 50, 138) -1.26667px -5.86667px 0px, rgb(236, 50, 138) -0.266667px -6px 0px, rgb(236, 50, 138) 0.716667px -5.95px 0px, rgb(236, 50, 138) 1.7px -5.75px 0px, rgb(236, 50, 138) 2.63333px -5.38333px 0px, rgb(236, 50, 138) 3.48333px -4.88333px 0px, rgb(236, 50, 138) 4.25px -4.23333px 0px, rgb(236, 50, 138) 4.9px -3.46667px 0px, rgb(236, 50, 138) 5.4px -2.61667px 0px, rgb(236, 50, 138) 5.76667px -1.68333px 0px, rgb(236, 50, 138) 5.96667px -0.7px 0px;
      }

    }

    &--03 {
      
      &::before {
        position: absolute;
        top: 0px;
        right: -600px;
        z-index: -1;
        opacity: 0.2;
        background-image: url(../img/shadow-2.png);
        background-position: center;
        background-repeat: no-repeat;
        width: 984px;
        height: 571px;
        content: "";
      }

    }

    &--02,
    &--04 {

      &::before {
        position: absolute;
        top: 0px;
        left: -700px;
        z-index: -1;
        opacity: 0.2;
        background-image: url(../img/shadow-1.png);
        background-position: center;
        background-repeat: no-repeat;
        width: 984px;
        height: 571px;
        content: "";
      }

      .profile-single__img {
        @include rotateElement( 353deg );

        > div {
          float: right;

          @include breakpoint(xs) {
            float: none;
          }

        }

        &::after {
          position: absolute;  
          top: 30px;
          left: 65px;
          z-index: -1;
          @include rotateElement( 353deg );
          border-style: solid;
          border-width: 200px 200px 0 0;
          border-color: #30d8d5 transparent transparent transparent;
          width: 0;
          height: 0;
          content: "";

          @include breakpoint(sm-below) {
            display: none !important;
          }

        }

      }

      .profile-single__name span {
        text-shadow: rgb(48, 216, 214) 6px 0px 0px, rgb(48, 216, 214) 5.91667px 1px 0px, rgb(48, 216, 214) 5.66667px 1.96667px 0px, rgb(48, 216, 214) 5.26667px 2.88333px 0px, rgb(48, 216, 214) 4.71667px 3.71667px 0px, rgb(48, 216, 214) 4.03333px 4.43333px 0px, rgb(48, 216, 214) 3.25px 5.05px 0px, rgb(48, 216, 214) 2.36667px 5.51667px 0px, rgb(48, 216, 214) 1.41667px 5.83333px 0px, rgb(48, 216, 214) 0.416667px 5.98333px 0px, rgb(48, 216, 214) -0.566667px 5.96667px 0px, rgb(48, 216, 214) -1.55px 5.8px 0px, rgb(48, 216, 214) -2.5px 5.45px 0px, rgb(48, 216, 214) -3.36667px 4.96667px 0px, rgb(48, 216, 214) -4.15px 4.33333px 0px, rgb(48, 216, 214) -4.8px 3.58333px 0px, rgb(48, 216, 214) -5.33333px 2.75px 0px, rgb(48, 216, 214) -5.71667px 1.81667px 0px, rgb(48, 216, 214) -5.93333px 0.85px 0px, rgb(48, 216, 214) -6px -0.15px 0px, rgb(48, 216, 214) -5.88333px -1.15px 0px, rgb(48, 216, 214) -5.61667px -2.1px 0px, rgb(48, 216, 214) -5.2px -3px 0px, rgb(48, 216, 214) -4.61667px -3.83333px 0px, rgb(48, 216, 214) -3.91667px -4.53333px 0px, rgb(48, 216, 214) -3.11667px -5.13333px 0px, rgb(48, 216, 214) -2.21667px -5.56667px 0px, rgb(48, 216, 214) -1.26667px -5.86667px 0px, rgb(48, 216, 214) -0.266667px -6px 0px, rgb(48, 216, 214) 0.716667px -5.95px 0px, rgb(48, 216, 214) 1.7px -5.75px 0px, rgb(48, 216, 214) 2.63333px -5.38333px 0px, rgb(48, 216, 214) 3.48333px -4.88333px 0px, rgb(48, 216, 214) 4.25px -4.23333px 0px, rgb(48, 216, 214) 4.9px -3.46667px 0px, rgb(48, 216, 214) 5.4px -2.61667px 0px, rgb(48, 216, 214) 5.76667px -1.68333px 0px, rgb(48, 216, 214) 5.96667px -0.7px 0px;
      }

    }

    &--01 {

      .profile-single__name span {
        top: 10px;
        left: 160px;
        @include rotateElement( 338deg );
      }

    }

    &--02 {

      .profile-single__name span {
        top: 10px;
        left: -60px;
        @include rotateElement( 338deg );
      } 

    }

    &--03 {

      .profile-single__name span {
        top: 10px;
        left: 170px;
        @include rotateElement( 10deg );
      }

    }

    &--04 {

      .profile-single__name span {
        top: 10px;
        left: 240px;
        @include rotateElement( 334deg );
      } 

    }

    &--01,
    &--02,
    &--03,
    &--04 {

      .profile-single__name span {

        @include breakpoint(sm-below) {
          position: static;
          opacity: 0.5;
          margin-left: 10px;
          text-shadow: none;
          font-family: $base__font-family;
          font-size: 40px;
          font-weight: 400;
        }

      }

    }

  }

  &__img {
    position: relative;
    top: 0;
    
    &-item {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 111%;
      width: 87.5%;

      @include breakpoint(xs) {
        float: none;
        margin: 0 auto;
        width: 60%;
        padding-bottom: 91%;
      }

      @include breakpoint($max: 500) {
        width: 60%;
        padding-bottom: 81%;
      }

    }

  }

  &__name {
    position: relative;
    margin-top: 80px;
    text-transform: uppercase;
    color: #fff;
    font-size: 72px;
    font-weight: 800;

    @include breakpoint(md-below) {
      margin-top: 60px;
      font-size: 52px;
    }

    @include breakpoint(xs) {
      margin-top: 30px;
      font-size: 40px;
    }

    span {
      position: absolute;
      font-family: $secondary__font-family;
      font-size: 90px;
    }

  }

  &__text {
    margin-top: 45px;
    line-height: 24px;
    color: #fff;
    font-size: 16px;

    @include breakpoint(xs) {
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 22px;
      font-size: 15px;
    }

    p {
      line-height: 24px;
      font-size: 16px;

      @include breakpoint(xs) {
        line-height: 22px;
        font-size: 15px;
      }

    }

    &--location {
      margin-bottom: 45px;

      @include breakpoint(xs) {
        margin-bottom: 15px;
      }

    }

    &--likes {
      position: relative;
      margin-top: 15px;
      padding-left: 68px;
      font-weight: 600;

      span {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 400;
      }

    }

  }

}