@media (max-width: 991px) {

  .header {

    .inside {
      padding: 0;
    }

    .navbar-toggle {
      right: 10px;
    }

    .navbar-collapse.collapse.in {
      display: block !important;
    }

  }

  .navbar-collapse {
    position: relative;
    float: none;
    top: 69px;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
    z-index: 998;
    height: auto !important;
    overflow: visible !important;
  }

  .navbar-collapse .navbar-left {
    float: none !important;
  }

  .navbar-nav {
    display: block;
    margin: 0;
    background: $mobile-menu__bg;
    min-width: 100%;
    width: 100%;
    max-width: 100%;

    > li {

      display: block;
      float: none;
      margin-left: 0;
      border-top: 1px solid lighten($mobile-menu__link-color,35%);
      padding: 0;

      > .nav__link {
        padding: 5px 20px 6px 20px;
        color: $mobile-menu__link-color;
        font-size: 16px;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
          background: lighten($mobile-menu__link-color,43%);
          color: darken($mobile-menu__link-color-hover,5%);
          font-weight: 700;
        }

      }

    }

  }

}