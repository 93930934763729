// ----------------------------
//     BLOG - blog-post
// ----------------------------

.blog-post {
} 

.post {
  position: relative;
  margin-bottom: 80px;
  color: #fff;

  &-single {

    p {
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
      
    }

  } 

  @include breakpoint(md-below) {
    margin-bottom: 50px;
  }

  @include breakpoint(xs) {
    margin-bottom: 30px;
  }

  &::before {
    position: absolute;
    top: 50px;
    left: -550px;
    opacity: 0.2;
    @include rotateElement( 340deg );
    background-image: url(../img/shadow.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 500px;
    height: 87px;
    content: "";
  }

  &--first {

    &::before {
      display: none;
    }

  }

  &__heading {
    margin-top: 8px;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 800;

    @include breakpoint(md-below) {
      font-size: 30px;
    }

    @include breakpoint(xs) {
      font-size: 26px;
    }

    a {
      color: #fff;

      &:hover {
        color: #fff;
        opacity: 0.8;
      }

    }

  }

  &__date {
    font-size: 14px;
  }

  &__body {
    padding-top: 24px;
    line-height: 28px;
    font-size: 17px;

    p {
      line-height: 28px;
      font-size: 17px;

      @include breakpoint(md-below) {
        line-height: 26px;
        font-size: 16px;
      }

      @include breakpoint(xs) {
        line-height: 24px;
        font-size: 15px;
      }

    }

    @include breakpoint(md-below) {
      line-height: 26px;
      font-size: 16px;
    }

    @include breakpoint(xs) {
      line-height: 24px;
      font-size: 15px;
    }

    a {
      text-decoration: underline;
      color: #fff;

      &:hover {
        text-decoration: underline;
        color: #fff;
        opacity: 0.8;
      }

    }

  }

}

.blog-pagination {
  height: 58px;
}