// ----------------------------
//     GENERAL STYLES
// ----------------------------

html {
    height: 100%;
    min-width: 320px;
    overflow: hidden;
}

body {
    margin: 0;
    background: $base__background-color;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-x: hidden;

    &.body--login { 
        background: #30d8d6;
    }

    &.body--blog { 
        position: relative;
        background: #ec328a;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin-top: -29%;
            background: rgba(48,216,213,1);
            background: -moz-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(48,216,213,1)), color-stop(72%, rgba(48,216,213,0)));
            background: -webkit-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -o-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -ms-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: radial-gradient(ellipse at center, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30d8d5', endColorstr='#30d8d5', GradientType=1 );
            content: "";

            @include breakpoint(xs) {
                opacity: 0.5;
            }

        }

    }

    &.body--profiles { 
        position: relative;
        background: #ec328a;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin-top: -29%;
            background: rgba(48,216,213,1);
            background: -moz-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(48,216,213,1)), color-stop(72%, rgba(48,216,213,0)));
            background: -webkit-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -o-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: -ms-radial-gradient(center, ellipse cover, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            background: radial-gradient(ellipse at center, rgba(48,216,213,1) 0%, rgba(48,216,213,0) 72%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30d8d5', endColorstr='#30d8d5', GradientType=1 );
            content: "";

            @include breakpoint(xs) {
                opacity: 0.5;
            }

        }

    }

}

.inside {
    margin: 0 auto;
    padding: 0 15px;
    min-width: $container__width-min;
    max-width: $container__width;
}

.btn-box {
    font-size: 0;

    .btn {
        margin-right: 5px;
        margin-left: 5px;
    }

}

*::after,
*::before {
    pointer-events: auto;
}

// ----------------------------
//     PAGE TITLE
// ----------------------------

.page-title {
    margin: 0 auto 80px auto;
    background-image: url(../img/page-title.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 861px;
    height: 211px;

    @include breakpoint(md-below) {
        margin: 0 auto 50px auto;
        background-size: 500px 123px;
        width: 500px;
        height: 123px;
    }

    @include breakpoint(xs) {
        margin: 0 auto 30px auto;
        background-size: 290px 71px;
        width: 290px;
        height: 71px;
        font-size: 50px;
    }

    &__text {
        @include rotateElement( 350deg );
        padding-top: 54px;
        color: $second-color;
        font-family: $secondary__font-family;
        font-size: 72px;

        @include breakpoint(md-below) {
            padding-top: 34px;
            font-size: 42px;
        }

        @include breakpoint(xs) {
            padding-top: 14px;
            font-size: 32px;
        }

    }

}

// ----------------------------
//     HEADINGS
// ----------------------------

.content-heading {
    margin-top: 58px;
    margin-bottom: 58px;
    text-transform: uppercase;
    text-align: center;
    color: $main-color;
    font-size: 36px;
    font-weight: 800;  

    @include breakpoint(md-below) {
        margin-top: 38px;
        margin-bottom: 38px;
        font-size: 30px;
    }

    @include breakpoint(xs) {
        margin-top: 28px;
        margin-bottom: 28px;
        font-size: 25px;
    }

    &--bottom {
        margin-bottom: -20px;

        @include breakpoint(md-below) {
            margin-bottom: -20px;
        }

    }

    &__green-triangle-bottom {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        color: #fff;
        z-index: 2;

        @include breakpoint(xs) {
            margin-right: 0;
            padding: 0 15px;
            color: #30d8d6;
        }

        &::after {
            position: absolute;
            right: -110px;
            top: -95px;
            z-index: -1;
            border-style: solid;
            border-width: 0 150px 250px 1240px;
            border-color: transparent transparent $main-color transparent;
            width: 0;
            height: 0;
            content: "";

            @include breakpoint(xs) {
                display: none;
            }

        }

        &::before {
            position: absolute;
            right: -1200px;
            top: -290px;
            z-index: -1;
            @include rotateElement( 13deg );
            border-style: solid;
            border-width: 0 620px 290px 480px;
            border-color: transparent transparent #f2f2f2 transparent;
            width: 0;
            height: 0;
            content: "";

            @include breakpoint(md-below) {
                display: none;
            }

        }

    }

    &__green-triangle-bottom-profile {
        position: relative;
        display: inline-block;
        margin-right: 48px;
        color: #fff;
        z-index: 2;



        @include breakpoint(xs) {
            margin-right: 0;
            padding: 0 15px;
            color: #30d8d6;
        }

        &::after {
            position: absolute;
            right: -110px;
            top: -95px;
            z-index: -1;
            border-style: solid;
            border-width: 0 100px 170px 1240px;
            border-color: transparent transparent $main-color transparent;
            width: 0;
            height: 0;
            content: "";

            @include breakpoint(xs) {
                display: none;
            }

        }

    }

    .twoline {
        display: inline-block;
        padding-left: 70px;
        text-align: left;
        color: #fff;

        span {
            display: block;
        }

        a,
        a:hover {
            text-decoration: underline;
            color: #fff;
        }

        a:hover {
            opacity: 0.8; 
        }

    }

    &__green-triangle-top {
        position: relative;
        display: block;
        margin-top: -6px;
        text-align: left;
        color: #fff;
        font-size: 48px;
        z-index: 2;

        @include breakpoint(md-below) {
            font-size: 40px;
        }

        @include breakpoint(sm-below) {
            margin-bottom: 40px;
            font-size: 30px;
        }

        @include breakpoint(xs) {
            margin-top: -20px;
            text-align: center;
            font-size: 25px;
        }

        span {
            display: block;

            @include breakpoint(xs) { 
                display: inline-block;
            }

            &.home-profile__link {
                text-align: right;

                a, 
                a:hover {
                    text-decoration: underline;
                    color: #fff;
                }

                a:hover {
                    opacity: 0.8;
                }

            }

        }

        &::after {
            position: absolute;
            top: -110px;
            right: -210px;
            z-index: -1;
            border-style: solid;
            border-width: 300px 230px 0 1440px;
            border-color: $main-color transparent transparent transparent;
            width: 0;
            height: 0;
            content: "";

            @include breakpoint(md-below) { 
                top: -120px;
            }

            @include breakpoint(sm-below) { 
                top: -155px;
            }

            @include breakpoint(xs) { 
                top: -175px;
                right: -130px;
            }

        }

    }

    &__pink-triangle-top {
        position: relative;
        display: block;
        margin-top: 14px;
        text-align: left;
        color: #fff;
        font-size: 48px;
        z-index: 2;

        @include breakpoint(md-below) {
            text-align: center;
            font-size: 40px;
        }

        @include breakpoint(sm-below) {
            margin-bottom: 40px;
            font-size: 30px;
        }

        @include breakpoint(xs) {
            margin-top: 10px;
            text-align: center;
            font-size: 25px;
        }

        a, 
        a:hover {
            margin-left: 55px;
            text-decoration: underline;
            color: #fff;

            @include breakpoint(md-below) {
                margin-left: 0;
            }

        }

        a:hover {
            opacity: 0.8;
        }

        &::after {
            position: absolute;
            top: -110px;
            right: -210px;
            left: -113px;
            z-index: -1;
            border-style: solid;
            border-width: 270px 1440px 0 200px; 
            border-color: $second-color transparent transparent transparent;
            width: 0;
            height: 0;
            content: "";

            @include breakpoint(md-below) {
                display: none;
            }

        }

    }

    &__green-triangle-clean {
        display: block;
        text-align: left;
        font-size: 44px;
        z-index: 2;

        @include breakpoint(md-below) {
            font-size: 40px;
            text-align: center;
        }

        @include breakpoint(sm-below) {
            padding: 0 15px;
            font-size: 35px;
        }

        @include breakpoint(xs) {
            font-size: 30px;
        }

        .home-hot {

            &__main {
                position: relative;
                display: block;
                color: $second-color;
                text-align: right;
                font-size: 60px;

                @include breakpoint(md-below) {
                    font-size: 50px;
                }

                @include breakpoint(sm-below) {
                    display: inline-block;
                    text-align: center;
                    font-size: 35px;
                }

                @include breakpoint(xs) {
                    font-size: 30px;
                }

            }

            &__sub {
                position: absolute;
                top: 30px;
                right: -70px;
                @include rotateElement( 346deg );
                text-shadow: rgb(255, 255, 255) 7px 0px 0px, rgb(255, 255, 255) 6.93333px 1px 0px, rgb(255, 255, 255) 6.71667px 1.96667px 0px, rgb(255, 255, 255) 6.36667px 2.91667px 0px, rgb(255, 255, 255) 5.88333px 3.78333px 0px, rgb(255, 255, 255) 5.28333px 4.58333px 0px, rgb(255, 255, 255) 4.58333px 5.3px 0px, rgb(255, 255, 255) 3.78333px 5.88333px 0px, rgb(255, 255, 255) 2.9px 6.36667px 0px, rgb(255, 255, 255) 1.96667px 6.71667px 0px, rgb(255, 255, 255) 1px 6.93333px 0px, rgb(255, 255, 255) 0px 7px 0px, rgb(255, 255, 255) -1px 6.93333px 0px, rgb(255, 255, 255) -1.98333px 6.71667px 0px, rgb(255, 255, 255) -2.91667px 6.36667px 0px, rgb(255, 255, 255) -3.78333px 5.88333px 0px, rgb(255, 255, 255) -4.58333px 5.28333px 0px, rgb(255, 255, 255) -5.3px 4.58333px 0px, rgb(255, 255, 255) -5.9px 3.78333px 0px, rgb(255, 255, 255) -6.36667px 2.9px 0px, rgb(255, 255, 255) -6.71667px 1.96667px 0px, rgb(255, 255, 255) -6.93333px 0.983333px 0px, rgb(255, 255, 255) -7px -0.0166667px 0px, rgb(255, 255, 255) -6.93333px -1px 0px, rgb(255, 255, 255) -6.71667px -1.98333px 0px, rgb(255, 255, 255) -6.36667px -2.91667px 0px, rgb(255, 255, 255) -5.88333px -3.8px 0px, rgb(255, 255, 255) -5.28333px -4.6px 0px, rgb(255, 255, 255) -4.58333px -5.3px 0px, rgb(255, 255, 255) -3.76667px -5.9px 0px, rgb(255, 255, 255) -2.9px -6.36667px 0px, rgb(255, 255, 255) -1.96667px -6.71667px 0px, rgb(255, 255, 255) -0.983333px -6.93333px 0px, rgb(255, 255, 255) 0.0166667px -7px 0px, rgb(255, 255, 255) 1.01667px -6.93333px 0px, rgb(255, 255, 255) 1.98333px -6.71667px 0px, rgb(255, 255, 255) 2.91667px -6.36667px 0px, rgb(255, 255, 255) 3.8px -5.88333px 0px, rgb(255, 255, 255) 4.6px -5.28333px 0px, rgb(255, 255, 255) 5.3px -4.56667px 0px, rgb(255, 255, 255) 5.9px -3.76667px 0px, rgb(255, 255, 255) 6.36667px -2.9px 0px, rgb(255, 255, 255) 6.71667px -1.95px 0px, rgb(255, 255, 255) 6.93333px -0.983333px 0px;
                color: $main-color;
                font-size: 44px;

                @include breakpoint(md-below) {
                    top: 40px;
                    right: 0;
                }

                @include breakpoint(sm-below) {
                    top: 30px;
                    font-size: 35px;
                }

                @include breakpoint(xs) {
                    font-size: 30px;
                }

            }

        }

    }

    &__green-triangle-top-right {
        position: relative;
        display: block;
        margin-top: 24px;
        text-align: left;
        line-height: 36px;
        color: #fff;
        font-size: 30px;
        z-index: 2;

        @include breakpoint(md-below) {
            padding: 0 15px;
        }

        span {
            display: block;

            @include breakpoint(sm-below) { 
                display: inline-block;
            }

            &.home-look__break {                

                a, 
                a:hover {
                    text-decoration: underline;
                    color: #fff;
                }

                a:hover {
                    opacity: 0.8;
                }

            }

        }

        &::after,
        &::before {
            position: absolute;
            top: -65px;
            left: -110px;
            z-index: -1;
            @include rotateElement( 358deg );
            border-style: solid;
            border-width: 205px 1700px 0 130px;
            border-color: $main-color transparent transparent transparent;
            width: 0;
            height: 0;
            content: "";
        }

        &::before {
            left: -109px;            
        }

        &::before,
        &::after {
            
            @include breakpoint(md-below) { 
                display: none;  
            }

        }

    }

}

// ----------------------------
//     IMG ALIGN
// ----------------------------

.alignright {
    float: right;
    margin: 0 0 0.5em 1em;
}

.alignleft {
    float: left;
    margin: 0 1em 0.5em 0;
}

.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// ----------------------------
//     VERTICAL ALIGN
// ----------------------------

.vcenter {
    display: table;
    width: 100%;
    height: 100%;

    &-box {
        display: table-cell;
        vertical-align: middle;
    }

}

// ----------------------------
//     VERTICAL ALIGN 2
// ----------------------------

.center-elements {
    height: 100%;
    font-size: 0;

    &::before {
        content: '\200B';
        display: inline-block;
        min-height: inherit;
        height: inherit;
        vertical-align: middle;        
    }

    > div,
    > img {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
    }

}

// ----------------------------
//     ROW
// ----------------------------

.row {

    &.row--smaller {
        margin-right: -7px;
        margin-left: -7px;

        > div {
            padding: 0 7px;
        }
        
    }

}

// ----------------------------
//     PAGINATION
// ----------------------------

.main-pagination {
    margin: 20px auto 40px auto;
    max-width: 800px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: 700;

    @include breakpoint(md-below) {
        font-size: 22px;
    }

    @include breakpoint(sm-below) {
        padding: 0 15px;
    }

    @include breakpoint(xs) {
        font-size: 18px;
    }

    li:first-of-type {
        float: left;
    }

    li:last-of-type {
        float: right;
    }

    li {
        display: inline-block;
        vertical-align: middle;
    }

    &__page-item a {
        display: block;
        @include borderRadius(50%);
        border: 2px solid rgba(#ffffff,0);
        padding: 15px 22px;
        color: #fff;

        @include breakpoint(md-below) {
            padding: 10px 17px;
        }

        @include breakpoint(xs) {
            padding: 5px 12px;
        }

        &:hover {
            border: 2px solid rgba(#ffffff,1);
            color: #fff;
        }

        &.main-pagination__page-link-arrow {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 9px 17px;
            padding: 27px 28px;

            @include breakpoint(md-below) {
                padding: 22px 23px;
            }

            @include breakpoint(xs) {
                background-size: 6px 11px;
                padding: 14px 17px;
            }

            &--prev {
                background-image: url(../img/pagination-prev.svg);
            }

            &--next {
                background-image: url(../img/pagination-next.svg);
            }

        }

    }

    &__page-item.active a {
        border: 2px solid rgba(#ffffff,1);
    }

    span.main-pagination__page-link,
    span.main-pagination__page-link:hover {
        border: 2px solid rgba(#ffffff,0);
    }

}